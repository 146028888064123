import {inject, Injectable} from '@angular/core';
import {combineLatest, Observable, of, ReplaySubject, throwError} from 'rxjs';
import {catchError, filter, map, switchMap, take, tap} from 'rxjs/operators';
import {HttpErrorResponse} from '@angular/common/http';
import {EstimationNotestimFactory} from '@models/estimations/estimation/notestim/estimation-notestim.factory';
import Estimation from '@models/estimations/estimation/estimation.model';
import EstimationNotestim from '@models/estimations/estimation/notestim/estimation-notestim.model';
import Etude from '@models/etudes/etude/etude.model';
import {ToasterService} from '@shared/toaster/toaster.service';
import {UserService} from '@models/users/user/user.service';

@Injectable({providedIn: 'root'})
export class EstimationNotestimService {
    private _estimationNotestimFactory = inject(EstimationNotestimFactory);
    private _toasterService = inject(ToasterService);
    private _userService = inject(UserService);
    private _currentSource = new ReplaySubject<EstimationNotestim>(1);
    private _current$ = this._currentSource.asObservable();

    get current$(): Observable<EstimationNotestim> {
        return this._current$;
    }

    checkIfCurrentChanged$(estimation: Estimation, options = {} as {
        changedKeys: { linkRapportMedia: boolean }
    }): Observable<boolean> {
        const changedKeys = options.changedKeys || {};

        return this.current$.pipe(
            filter(estimationNotestim => !!estimationNotestim),
            take(1),
            switchMap(currentEstimationNotestim => this.emitCurrentIfChanged$(estimation).pipe(
                switchMap(() => this.current$),
                take(1),
                map(lastEstimationNotestim => ({currentEstimationNotestim, lastEstimationNotestim})),
            )),
            map(({currentEstimationNotestim, lastEstimationNotestim}) => {
                if (changedKeys.linkRapportMedia) {
                    return currentEstimationNotestim.linkRapportMedia !== lastEstimationNotestim.linkRapportMedia;
                }

                return true;
            }),
        );
    }

    currentStatutInfos(): void {
        const title = 'Mise à jour vers ' + Etude.enablableModules.NOTESTIM.label;

        this.current$.pipe(take(1)).subscribe(estimationNotestim => {
            if (estimationNotestim.isScanError()) {
                this._toasterService.error(
                    title,
                    'Une erreur est survenue lors de la mise à jour des données de votre bien en évaluation vers ' +
                    Etude.enablableModules.NOTESTIM.label + '.<br>' +
                    // @todo Rechercher cette phrase et factoriser
                    'Pour plus d\'informations, contactez le support technique.',
                    {enableHtml: true},
                );
            } else if (estimationNotestim.isScanInProgress()) {
                this._toasterService.info(
                    title,
                    'Les données de votre bien en évaluation sont en cours d\'envoi vers ' + Etude.enablableModules.NOTESTIM.label + '.',
                );
            }
        });
    }

    emitCurrentIfChanged$(estimation: Estimation): Observable<void> {
        return combineLatest([
            this.current$.pipe(filter(estimationNotestim => !!estimationNotestim), take(1)),
            this.getOne$(estimation),
        ]).pipe(map(([currentEstimationNotestim, lastEstimationNotestim]) => {
            if (currentEstimationNotestim.isDifferent(lastEstimationNotestim)) {
                this._currentSource.next(lastEstimationNotestim);
            }
        }));
    }

    getOne$(estimation: Estimation): Observable<EstimationNotestim> {
        return this._estimationNotestimFactory.get$(estimation, {headers: {handledStatusErrors: [404]}}).pipe(
            catchError((httpErrorResponse: HttpErrorResponse) => {
                if (httpErrorResponse.status === 404) {
                    return of(this._estimationNotestimFactory.createVirgin());
                }

                return throwError(() => httpErrorResponse);
            }),
        );
    }

    initCurrent(estimation: Estimation): void {
        this._currentSource.next(null!);
        if (!estimation || estimation.isNewOrIdNullish()) {
            return;
        }

        this._userService.last$.pipe(
            switchMap(currentUser => {
                if (currentUser.hasRoleEstimationNotestimV2() && estimation.couldUseNotestimV2()) {
                    return this.getOne$(estimation).pipe(tap(estimationNotestim => this._currentSource.next(estimationNotestim)));
                }

                return of(undefined);
            }),
            take(1),
        ).subscribe();
    }

    saveCurrent$(estimation: Estimation): Observable<EstimationNotestim> {
        return this._estimationNotestimFactory.save$(estimation).pipe(
            tap(estimationNotestim => this._currentSource.next(estimationNotestim)),
        );
    }
}
